$(document).ready(function() {
    //menu toggle
	$('.js-menu-toggle').on('click', function() {
        $(this).toggleClass('is-open');
    });
    
    //debounce test
    $(window).on('resize', _.debounce(function() {
//        console.log('debounce test');
    }, 300));
    
    //throttle test
    $(document).on('scroll', _.throttle(function(){
//        console.log('throtle test');
    }, 300));
    
    //priority navigation
    function responsiveNav() {
        var nav = priorityNav.init({
            initClass: 'priority-nav-ready',
            mainNavWrapper: '.js-main-nav',
            mainNav: '.js-main-nav-list',  
            breakPoint: 1024,
            navDropdownLabel: '',            
        });                                
    }
    
    //web font load - run priority nav after font is loaded
    WebFont.load({
        custom: {
            families: ['Akrobat']
        },
        active: function() {                                                        
            responsiveNav();
        },
        inactive: function() {                                    
            responsiveNav();
        }
    });
    
    //lazy slide background image loading
    function lazySlide(slider) {
        $('.slick-slide.slick-current', slider).addClass('slided');
        $('.slick-slide.slick-current', slider).next().addClass('slided');
        $('.slick-slide.slick-current', slider).prev().addClass('slided');
    }
    
    //cover slider / main slider
    if ( $('.js-cover__slider').length ) {
        $('.js-cover__slider').each(function() {
           var coverSlider = $(this);
           
           //cover slider on init        
           coverSlider.on('init', function(event, slick){
               lazySlide(coverSlider);
           });
           
           //cover slider init
           coverSlider.slick({
               arrows: false,
               dots: true,            
           });
           
           //cover slider after change                
           coverSlider.on('afterChange', function(event, slick){            
               lazySlide(coverSlider);
           }); 
        });        
    }    
    
    //wrap table in scrollable content
    if ( $('.text table').length ) {        
        $('.text table').each(function() {            
            $(this).wrap('<div class="scrollable-content"></div>');
        });
    }
    
    //stories slider
    if ( $('.js-stories').length ) {
        
        $('.js-stories').each(function() {
            var thisStory = $(this);
            var storiesSlider = thisStory.find('.js-stories__slider');            
            var storiesSliderNextBtn = $('.js-stories__slider-btn-next', thisStory);            
            var storiesSliderPrevBtn = $('.js-stories__slider-btn-prev', thisStory);            
            
            //stories slider on init        
            storiesSlider.on('init', function(event, slick){            
                lazySlide(storiesSlider);
            });
            
            //stories slider init
            storiesSlider.slick({
                fade: true,
                adaptiveHeight: true,
                prevArrow: storiesSliderPrevBtn,
                nextArrow: storiesSliderNextBtn,
                draggable: false,
                responsive: [{                
                    breakpoint: 480,
                    settings: {
                        fade: false,
                        draggable: true
                    }                
                }]
            });
            
            //stories slider after change                
            storiesSlider.on('afterChange', function(event, slick){            
                lazySlide(storiesSlider);
            });
        });        
    }
    
    //dual slider
    if ( $('.js-dual-slider').length ) {
        $('.js-dual-slider').each(function() {
            var dualSlider = $(this);
            
            var dualSlider1 = dualSlider.find('.js-dual-slider__half-1');
            var dualSlider2 = dualSlider.find('.js-dual-slider__half-2');
            
            var dualSliderNextBtn = $('.js-dual-slider__btn-next', dualSlider);            
            var dualSliderPrevBtn = $('.js-dual-slider__btn-prev', dualSlider);
            
            //stories slider on init        
            dualSlider1.on('init', function(event, slick){            
                lazySlide(dualSlider1);
            });
            
            dualSlider1.slick({
                asNavFor: dualSlider2,
                arrows: false,
//                lazyLoad: 'ondemand',                
            });
            
            //stories slider after change                
            dualSlider1.on('afterChange', function(event, slick){            
                lazySlide(dualSlider1);
            });
            
//            dualSlider1.on('lazyLoaded', function(event, slick, image, imageSource){              
//                //fix image pop on cloned slides
//                $('.slick-cloned [data-lazy="' + imageSource  + '"]').attr('src', imageSource).removeAttr('data-lazy data-srcset data-sizes');
//            });
            
            dualSlider2.slick({
                asNavFor: dualSlider1,
                prevArrow: dualSliderPrevBtn,
                nextArrow: dualSliderNextBtn,
            });                        
            
        });
    }
    
    //logo slider
    if ( $('.js-logo-slider').length ) {
        $('.js-logo-slider').each(function() {
            var logoSlider = $(this);
            
            logoSlider.slick({
                slidesToShow: 3,
                lazyLoad: 'ondemand',
                prevArrow: '<button type="button" class="logo-row__slider-btn logo-row__slider-btn--prev slick-prev"></button>',
                nextArrow: '<button type="button" class="logo-row__slider-btn logo-row__slider-btn--next slick-next"></button>',
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,                
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 1,                    
                        }
                    }
                ]
            });
            
            logoSlider.on('lazyLoaded', function(event, slick, image, imageSource){              
                //fix image pop on cloned slides
                $('.slick-cloned [data-lazy="' + imageSource  + '"]').attr('src', imageSource).removeAttr('data-lazy data-srcset data-sizes');
            });
        });
    }
    
    //media block slider
    if ( $('.js-media-block').length ) {
        $('.js-media-block').each(function() {
            var mediaBlock = $(this);
            var mediaBlockSlider = mediaBlock.find('.js-media-block__slider');
            
            var mediaSliderNextBtn = $('.js-media-block__slider-btn-next', mediaBlock);            
            var mediaSliderPrevBtn = $('.js-media-block__slider-btn-prev', mediaBlock);
            
            var mediaSliderCurrentSlide = $('.js-media-block__slider-current-slide', mediaBlock);
            var mediaSliderCount= $('.js-media-block__slider-count', mediaBlock);
            
            //media block slider init
            mediaBlockSlider.on('init', function(event, slick){                
                mediaSliderCount.text(slick.slideCount);
                mediaSliderCurrentSlide.text(slick.currentSlide + 1);
            });
            
            mediaBlockSlider.slick({
                fade: true,
                adaptiveHeight: true,
                lazyLoad: 'ondemand',
                prevArrow: mediaSliderPrevBtn,
                nextArrow: mediaSliderNextBtn,
                draggable: false,
                responsive: [{                
                    breakpoint: 768,
                    settings: {
                        fade: false,
                        draggable: true
                    }                
                }]
            });
            
            //media block slider after change                
            mediaBlockSlider.on('afterChange', function(event, slick){                
                mediaSliderCurrentSlide.text(slick.currentSlide + 1);
            });
                        
            mediaBlockSlider.on('lazyLoaded', function(event, slick, image, imageSource){              
                //fix image pop on cloned slides
                $('.slick-cloned [data-lazy="' + imageSource  + '"]').attr('src', imageSource).removeAttr('data-lazy data-srcset data-sizes');
            });
            
        });
    }
    
    //timeline slider
    if ( $('.js-timeline').length ) {
        $('.js-timeline').each(function() {
            var timeline = $(this);
            var timelineYearsSlider = $('.js-timeline-years__slider', timeline);
            var timelinePresidentSlider = $('.js-timeline-president__slider', timeline);
            
            timelineYearsSlider.slick({
                slidesToShow: 10,
                infinite: false,
                asNavFor: timelinePresidentSlider,
                focusOnSelect: true,
                focusOnChange: true,
                prevArrow: '<button type="button" class="timeline-years-slider__btn timeline-years-slider__btn--prev slick-prev"></button>',
                nextArrow: '<button type="button" class="timeline-years-slider__btn timeline-years-slider__btn--next slick-next"></button>',
                responsive: [
                {                
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 8,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 6,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 5,
                    },
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 3,
                    }              
                },
                {
                    breakpoint: 360,
                    settings: {
                        slidesToShow: 2,
                    }              
                }]
            });
            
            timelinePresidentSlider.slick({
                infinite: false,
                fade: true,
                arrows: false,
                lazyLoad: 'ondemand',
                adaptiveHeight: true,
                asNavFor: timelineYearsSlider,
            });
        });
    }
    
    //tabs
    if ( $('.js-tabs').length ) {
        function changeTab(thisTab) {
            $('.js-tab').removeClass('is-active');
            $('.js-tab[data-tab='+ thisTab +']').addClass('is-active');
            $('.js-tab-content').stop().slideUp();
            $('.js-tab-content[data-tab='+ thisTab +']').stop().slideDown(function() {                
                $('.js-tab-content[data-tab='+ thisTab +']').css('height', 'auto'); //reset tab content height
                $('html, body').animate({                                
                    scrollTop: $('.js-tabs').offset().top
                }, 250);
            });            
        }
        
        $('.js-tab').on('click', function() {             
            if ( !$(this).hasClass('is-active') ) {            
                var thisTab = $(this).data('tab');                            
                window.location.hash = thisTab;
            }
        });
        
        $(window).on('hashchange', function() {
            var tabHash = window.location.hash;                
            changeTab(tabHash.substring(1));
        });
        
        if ( window.location.hash ) {
            var tabHash = window.location.hash;            
            changeTab(tabHash.substring(1));
        }
    }
  
    //accordion
    if ( $('.js-accordion').length ) {
        $('.js-accordion__open').on('click', function() {
            $(this).toggleClass('is-open');
            $(this).parents('.js-accordion__item').find('.js-accordion__content').stop().slideToggle(200);
        });
    }
    
    //dropdown
    $('.js-custom-dropdown-btn').on('click', function() {        
        var dropdownBtn = $(this);
        var dropdownParrent = dropdownBtn.parent();
        var dropdownList = dropdownParrent.find('.js-custom-dropdown-list');                
        $('.js-custom-dropdown').not(dropdownParrent).removeClass('is-open');        
        dropdownParrent.toggleClass('is-open');                
    });
    
    //escape key press
    $(document).on('keyup', function(e) {
        if ( e.keyCode == 27 ) {             
            //close custom dropdown
            if ( $('.js-custom-dropdown').hasClass('is-open') ) {                 
                $('.js-custom-dropdown').removeClass('is-open');
            }
        }
    });
    
    //click outside
    $(document).on('click touchend', function(event) { 
        //close custom dropdown
        if ( !$(event.target).closest('.js-custom-dropdown').length ) {
            if ( $('.js-custom-dropdown').hasClass('is-open') ) {            
                $('.js-custom-dropdown').removeClass('is-open');
            }
        }
    });

    //light gallery  
    $('.js-lightgallery-dynamic').on('click', function() {        
        $(this).lightGallery({
            download: false,            
            dynamic: true,
            dynamicEl: gallery
        });     
    });
    
    //cookie bar
    if ( $('.js-cookie-bar').length ) {
        //check for cookie
        if ( !readCookie('cookieaccept') ) {
        	$('.js-cookie-bar').show();	
        }
        //cookie accept
        $('.js-cookie-accept').on('click', function() {
        	var date = new Date();
        	date.setTime(date.getTime() + (100 * 24 * 60 * 60 * 1000));
        	var expires = "expires=" + date.toGMTString();
        	document.cookie = 'cookieaccept' + "=true; " + expires + "; path=/";
        	
        	$('.js-cookie-bar').fadeOut();
        });	        
    }	
    
    //cookie
    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return false;
    }
});